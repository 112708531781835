import React from 'react';
// import style from './style.module.scss'


export default function Error() {
  return (
    <div>
      <p>Ошибка</p>
    </div>

  )
}
