import React from 'react';
import style from './style.module.scss'
import horoscope from "../../img/horoscope.png"
import card1 from "../../img/Taro1.svg"
import card2 from "../../img/Taro2.svg"
import card3 from "../../img/Taro3.svg"
import card4 from "../../img/Taro.svg"
import img_taro from "../../img/img_taro.png"


export default function Tarot() {
    return (
        <section className={style.conteuner}>
            <div className={style.conteuner__text}>
                <img className={style.conteuner__icon} src={img_taro} alt='Иконка гороскоп'></img>
                <h1 className={style.conteuner__text_header}>Таро</h1></div>
            <div className={style.cards}>
                <img className={style.cards__img2} src={card1} alt='Прайс Таро'></img>
                <img className={style.cards__img} src={card4} alt='Прайс Таро'></img>
                <img className={style.cards__img2} src={card3} alt='Прайс Таро'></img>
            </div>

            <div className={style.cards__smart}>
                <div id="carouselExample" class="carousel slide">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src={card2} className={style.cards__img3} alt="Полный разбор личности"></img>
                        </div>
                        <div class="carousel-item">
                            <img src={card1} className={style.cards__img3} alt="Финансовый код"></img>
                        </div>
                        <div class="carousel-item">
                            <img src={card3} className={style.cards__img3} alt="Любовный гороскоп"></img>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

        </section>

    )
}
