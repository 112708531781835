import React from "react"
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import style from "./style.module.scss"
import wing from "../../img/wing.png"
import prognostication from '../../img/Прогностика.png'
import natalChart from '../../img/Натальная карта.png'
import tarot from "../../img/Таро.png"
import photo from '../../img/photo1.svg'
// import star1 from '../../img/star1.gif'
// import star2 from '../../img/star3.gif'


export default function Home() {
    return (
        <>
            <div className={style.header}>
                <img className={style.header__photo} src={photo} alt="Основное фото" />
                <div className={style.header__text}><h1 className={style.header__text_H1}>Привет! Я Ensia.</h1>
                    <h2 className={style.header__text_H1}>Я профессиональный астролог.</h2>
                    <p className={style.header__text_p}>Благодаря Ensia, ты сможешь полностью познать себя с разных сторон - это поможет добиться высокой самореализации и стремительно идти по карьерной лестнице.</p>
                    <a href="https://wa.me/79108484886"><button className={style.header__text_btn}>Заказать прогноз</button></a>
                </div>
            </div>


            <section className={style.container}>

                <div className={style.content}>
                    <div>
                        <h2 className={style.content__header}>О бренде</h2>
                        <p className={style.content__text}>Привет! Я Ensia, и я профессиональный астролог. Я пришла к астрологии 3 года назад, пытаясь понять себя и найти направление куда мне двигаться. Эта наука безумно затянула меня, и я начала самостоятельно изучать её, делать небольшие разборы для себя и своих близких. Но год назад я поняла, что хочу помогать большому числу людей искать ответы на свои вопросы и стала квалифицированным астрологом. Сейчас Ensia это бренд, который может помочь раскрыть тебя, указать на твои сильные и слабые стороны, понять, где себя реализовывать и развивать. В дополнение показать твои прогнозы на определенный срок - я могу выявить разные события года: благополучные дни для покупок, травмоопасные дни, также дни финансовых вложений и сдачей экзаменов, когда планировать важные события года, а в какие даты лучше отказаться от глобальных планов. Согласись, знать заранее где жизнь может подставить подножку и быть готовым к этому, звучит намного привлекательнее, ведь порой всё выходит из под контроля, когда так хочется держать жизнь в своих руках.</p>

                    </div>
                    <div>
                        <h2 className={style.content__header}>Как Ensia может раскрыть тебя?</h2>
                        <div className={style.content__columnText}>
                            <p className={style.content__text}><img className={style.content__text_wing} src={wing} alt="звезда" />Благодаря Ensia, ты сможешь полностью познать себя с разных сторон - это поможет добиться высокой самореализации и стремительно идти по карьерной лестнице. </p>
                            <p className={style.content__text}><img className={style.content__text_wing} src={wing} alt="звезда" />Сможешь понять восприятие тебя окружением и как именно произвести первое хорошее впечатление на людей. Это будет бонусом к построению отношений с мужчиной твоей мечты.</p>
                            <p className={style.content__text}><img className={style.content__text_wing} src={wing} alt="звезда" />И наконец, ты сможешь ответить на главный вопрос: «что мне делать дальше?»</p>
                        </div>
                    </div>
                </div>

                <div className={style.container__cards}>
                    <NavLink to='/prognostics'><img className={style.container__cards_item} src={prognostication} alt="Раздел прогностика" /></NavLink>
                    <NavLink to='/natalchart'><img className={style.container__cards_item} src={natalChart} alt="Раздел натальная карта" /></NavLink>
                    <NavLink to='/tarot'><img className={style.container__cards_item} src={tarot} alt="Раздел таро" /></NavLink>
                </div>
                <section className={style.container__questions}>

                    <div class="accordion accordion-flush" id="accordionFlushExample">

                        <div class="accordion-item" className={style.cards__questions}>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne" className={style.cards__questions_header}>
                                    Какие аспекты моего характера могут быть выявлены из моей натальной карты?
                                </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body" className={style.cards__questions_text}>Могут быть выведены негативные и положительные черты характера.   Например личностное отношение к близким людям, друзьям и коллегам. Ваша продуктивность и активность, также коммуникабельность, умение находить общий язык с людьми или же наоборот - умение настоять на своем и проявить лидерские качества. </div>
                            </div>
                        </div>

                        <div class="accordion-item" className={style.cards__questions}>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo" className={style.cards__questions_header}>
                                    Какие ключевые события могут быть предсказаны на основе моей натальной карты?
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body" className={style.cards__questions_text}>Взлеты и падения в карьере, возможности заработать деньги или же не запланированные траты, встреча мужчины мечты, разногласия в отношениях, возможные переезды, возникновение проблем со здоровьем.</div>
                            </div>
                        </div>

                        <div class="accordion-item" className={style.cards__questions}>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree" className={style.cards__questions_header}>
                                    Как моя натальная карта влияет на мои отношения с другими людьми?
                                </button>
                            </h2>
                            <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body" className={style.cards__questions_text}>Натальная карта подсвечивает нам разные сферы жизни и отношений. Мы можем увидеть как Вы общаетесь, с какими людьми Вам комфортнее, какие проблемы могут возникать и как с этим работать.</div>
                            </div>
                        </div>

                        <div class="accordion-item" className={style.cards__questions}>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseThree" className={style.cards__questions_header}>
                                    Я не знаю какой разбор хочу и как правильно выбирать.
                                </button>
                            </h2>
                            <div id="flush-collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body" className={style.cards__questions_text}>Если Вы сомневаетесь в выборе разборов - свяжитесь со мной. Я подробно всё расскажу, выслушаю Ваш запрос и помогу определиться с выбором. </div>
                            </div>
                        </div>

                        <div class="accordion-item" className={style.cards__questions}>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseThree" className={style.cards__questions_header}>
                                    Какие сферы жизни наиболее сильно подвержены влиянию планет в моей натальной карте?
                                </button>
                            </h2>
                            <div id="flush-collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body" className={style.cards__questions_text}>Натальная карта индивидуальна у каждого человека - планеты влияют на какие то сферы больше, а на какие то меньше. В разборе я смогу Вам подсветить какие сферы более повреждены, а какие в сильном положении. </div>
                            </div>
                        </div>

                        <div class="accordion-item" className={style.cards__questions}>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseThree" className={style.cards__questions_header}>
                                    Как можно использовать карты таро для принятия решений и решения проблем?
                                </button>
                            </h2>
                            <div id="flush-collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body" className={style.cards__questions_text}>Вы можете задать мне вопрос, который Вас интересует. Я помогу с формулировкой вопроса, если вы сомневаетесь. Карты покажут Вам пути выхода из любой ситуации.</div>
                            </div>
                        </div>

                        <div class="accordion-item" className={style.cards__questions}>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseThree" className={style.cards__questions_header}>
                                    Какая роль астрологии и таро может сыграть в моем личностном развитии?
                                </button>
                            </h2>
                            <div id="flush-collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body" className={style.cards__questions_text}>Мы можем использовать таро и астрологию, как ключик в жизни. Зачем ломится в закрытую дверь, когда уже все написано в какую сторону нам двигаться и развиваться. Таро и Астрология помогут Вам максимально эффективно развиваться самыми быстрыми путями.</div>
                            </div>
                        </div>

                        <div class="accordion-item" className={style.cards__questions}>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseThree" className={style.cards__questions_header}>
                                    Какие предсказания можно сделать на основе комбинаций карт таро и моей натальной карты?
                                </button>
                            </h2>
                            <div id="flush-collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body" className={style.cards__questions_text}>С помощью Натальной карты мы можем посмотреть наш характер, сильные и слабые стороны, финансы, карьерную реализацию и отношения. В таро я дополнительно могу ответить на Ваш вопрос. К примеру Вы не знаете менять Вам работу или нет, в натальной карте мы смотрим подходит ли Вам эта профессия, а таро даст уточняющий ответ на действия в ближайшие 3-6 месяцев.</div>
                            </div>
                        </div>

                        <div class="accordion-item" className={style.cards__questions}>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseThree" className={style.cards__questions_header}>
                                    Что такое астрология и какие аспекты жизни она охватывает?
                                </button>
                            </h2>
                            <div id="flush-collapseNine" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body" className={style.cards__questions_text}>Астрология- наука о движении небесных тел и их влияние на жизнь людей. Есть разные виды астрологии, я в своих разборах использую Натальную астрологию, Прогностическую и Синастрию. Натальная карта - наш паспорт личности, который показывает характер, способности, потенциал, сценарии жизни и убеждения. Прогностика описывает общие тенденции и события года человека. Сенестрия - гороскоп совместимости двух людей.</div>
                            </div>
                        </div>

                        <div class="accordion-item" className={style.cards__questions}>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseThree" className={style.cards__questions_header}>
                                    Какие методы и техники используются в астрологии для чтения натальных карт?
                                </button>
                            </h2>
                            <div id="flush-collapseTen" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body" className={style.cards__questions_text}>Методом анализа нахождения планет в знаке, домов и их аспектов. Опираясь на эти знания я расшифровываю Вашу Натальную карту, давая точную характеристику о Вас и отвечая на интересующие вопросы.</div>
                            </div>
                        </div>

                        <div class="accordion-item" className={style.cards__questions}>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseThree" className={style.cards__questions_header}>
                                    Какие данные необходимы для составления натальной карты?
                                </button>
                            </h2>
                            <div id="flush-collapseEleven" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body" className={style.cards__questions_text}>Для разбора мне нужна Ваша дата, место и время рождения. Не зная хотя бы одного из пунктов разбор не возможен. Но с помощью астрологии мы можем выявить время Вашего рождения в промежутке  от 1 часа до 1,5 часов.</div>
                            </div>
                        </div>

                        <div class="accordion-item" className={style.cards__questions}>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseThree" className={style.cards__questions_header}>
                                    Какие принципы этики и конфиденциальности соблюдаются в работе астролога?
                                </button>
                            </h2>
                            <div id="flush-collapseTwelve" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body" className={style.cards__questions_text}>Безусловно данные, полученные астрологом являются строго личными. И мы не распространяем информацию о наших клиентах, так как являемся профессионалами своего дела.</div>
                            </div>
                        </div>

                        <div class="accordion-item" className={style.cards__questions}>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThree" className={style.cards__questions_header}>
                                    Какие методы и техники астролог использует для составления прогнозов?
                                </button>
                            </h2>
                            <div id="flush-collapseThirteen" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body" className={style.cards__questions_text}>Используются методы - <NavLink to='/prognostics' className={style.href}>Соляр</NavLink>, <NavLink to='/prognostics' className={style.href}>Транзит</NavLink> и <NavLink to='/prognostics' className={style.href}>Дирекция</NavLink>. Подробнее узнать о данных методах и в каких именно разборах они используются, Вы можете перейдя по ссылкам. </div>
                            </div>
                        </div>

                        <div class="accordion-item" className={style.cards__questions}>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collapseThree" className={style.cards__questions_header}>
                                    Какие ключевые события и изменения могут быть предсказаны в моей жизни?
                                </button>
                            </h2>
                            <div id="flush-collapseFourteen" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body" className={style.cards__questions_text}>С помощью астрологии мы можем увидеть вероятность брака и начала отношений, смена работы или повышения по карьерной лестнице, неожиданные денежные выплаты, сложности с деньгами, болезни, конфликты в отношениях, переезд, покупка нового жилья и даже благоприятное время для похудения. Астрология поможет указать нам любые события, которые Вас интересуют, в зависимости от Ваших запросов.</div>
                            </div>
                        </div>

                        <div class="accordion-item" className={style.cards__questions}>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFifteen" aria-expanded="false" aria-controls="flush-collapseThree" className={style.cards__questions_header}>
                                    Как долго обычно охватывают прогнозы астролога – недели, месяцы, годы?
                                </button>
                            </h2>
                            <div id="flush-collapseFifteen" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body" className={style.cards__questions_text}>Все прогнозы строятся от полугода до года, так как это наиболее точный точный промежуток временят для расклада. </div>
                            </div>
                        </div>

                        <div class="accordion-item" className={style.cards__questions}>
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSixteen" aria-expanded="false" aria-controls="flush-collapseThree" className={style.cards__questions_header}>
                                    Как долго делаются прогнозы Натальной карты и таро?
                                </button>
                            </h2>
                            <div id="flush-collapseSixteen" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body" className={style.cards__questions_text}>Время варьируется от трех дней до двух недель, в зависимости от типа расклада. Утверждение сроков может производится индивидуально, в зависимости от запроса.</div>
                            </div>
                        </div>

                    </div>
                </section>

                {/* <section className={style.carousel__questions}>
                    <div id="carouselExample" class="carousel slide">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div><h3 className={style.carousel__questions_header}>Какие аспекты моего характера могут быть выявлены из моей натальной карты?</h3><p className={style.carousel__questions_text}>Могут быть выведены негативные и положительные черты характера. Например личностное отношение к близким людям, друзьям и коллегам. Ваша продуктивность и активность, также коммуникабельность, умение находить общий язык с людьми или же наоборот - умение настоять на своем и проявить лидерские качества.</p></div>
                            </div>
                            <div class="carousel-item">
                                <div><h3 className={style.carousel__questions_header}>Какие ключевые события могут быть предсказаны на основе моей натальной карты?</h3></div>
                            </div>
                            <div class="carousel-item">
                                <div><h3 className={style.carousel__questions_header}>Как моя натальная карта влияет на мои отношения с другими людьми?</h3></div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>

                </section> */}

            </section >
        </>
    )
}