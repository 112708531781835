import React from 'react';
import style from './style.module.scss'


export default function Prices() {
    return (
        <section className={style.container}>
            <h1 className={style.content__header}>Прайс</h1>
            <div className={style.table}>
                <div><p className={style.table_text}>Полный разбор личности</p></div>
                <div><p className={style.table_text}>4779 р.</p></div>
                <div><p className={style.table_text}>Финансовый код</p></div>
                <div><p className={style.table_text}>2779 р.</p></div>
                <div><p className={style.table_text}>Любовный гороскоп</p></div>
                <div><p className={style.table_text}>2779 р.</p></div>
                <div><p className={style.table_text}>Синастрия</p></div>
                <div><p className={style.table_text}>1779 р.</p></div>
                <div><p className={style.table_text}>Кармическая задача</p></div>
                <div><p className={style.table_text}>1279 р.</p></div>
                <div><p className={style.table_text}>Соляр</p></div>
                <div><p className={style.table_text}>3779 р.</p></div>
                <div><p className={style.table_text}>Годовой прогноз</p></div>
                <div><p className={style.table_text}>5779 р.</p></div>

            </div>
        </section>

    )
}
